import React from "react";
import Header from "./ComponentsWeb/Sub/Header";
import Footer from "./ComponentsWeb/Sub/Footer";
import payment from "./images/payment.jpg";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { orderCreate } from "./reducers/orderDetailsReducer";

export default function PaymentQR() {
  const queryParameters = new URLSearchParams(window.location.search);
  const totalamount = queryParameters.get("totalamount");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Row className="m-auto">
        <Col md={12} className="text-center mt-3">
          <h3 className="text-dark">
            <b>Payable Amount {totalamount}</b>
          </h3>
        </Col>
        <Col md={12}>
          <img src={payment} alt="payment" />
        </Col>
      </Row>
        {/* <Col md={6} xs={6} sm={6} className="text-center">
          <Button
            onClick={async () => {
              const apiResp = await dispatch(orderCreate());
              if (apiResp.payload.status) {
                navigate("/user-Dashboard");
              }
            }}
            variant="success"
          >
            Orders
          </Button>
        </Col> */}
      <Row className="mt-2 mb-3">
        <Col md={12} xs={12} sm={12} className="text-center">
          <p className="text-danger mb-0">
            If your payment has been completed, please submit UTR number.
          </p>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={8} sm={8} xs={8}>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Enter UTR number" />
            </Form.Group>
          </Col>
          <Col md={4} sm={4} xs={4}>
            <Button
              variant="warning"
              onClick={async () => {
                const apiResp = await dispatch(orderCreate());
                if (apiResp.payload.status) {
                  navigate("/");
                }
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
